.documentDiv {
  height: 100%;
}
.pageDiv {
  border-bottom: 1px dashed gainsboro;
}
.thumbnailPage .react-pdf__Thumbnail__page {
  padding: 10px;
  border-radius: 12px;
  border: 1px solid gainsboro;
  /* background-color: rgb(247, 252, 255); */
}
.visiblePage .react-pdf__Thumbnail__page {
  border: solid 2px #b0c7da;
}
