@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap");

body {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  background: #fafafa;
  margin: 0px;
  overflow: auto;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: 0.15s;
  transition-delay: 0.15s;
}

.fade-exit {
  opacity: 1;
  margin-top: -100vh;
}

.fade-exit-active {
  margin-top: -100vh;
  opacity: 0;
  transition: 0.15s;
}

.navHeader-enter {
  opacity: 0;
}

.navHeader-enter-active {
  opacity: 1;
  transition: 0.3s;
}

.navHeader-exit {
  opacity: 1;
}

.navHeader-exit-active {
  opacity: 0;
  transition: 0.2s;
}

.logoSmall-enter {
  opacity: 0;
}

.logoSmall-enter-active {
  opacity: 1;
  transition: 0.6s;
  transition-delay: 0.2s;
}

.logoSmall-exit {
  opacity: 1;
}

.logoSmall-exit-active {
  opacity: 0;
  transition: 0.3s;
}

.MuiGridPanelWrapper-root {
  border-radius: 10px;
}

.MuiPaper-root.MuiGridPanel-paper {
  border-radius: 10px;
  position: absolute;
  padding: 20px;
}

-webkit-scrollbar {
  width: 12px;
}
-webkit-scrollbar-track {
  margin: 5px 0px 5px 0px;
}
-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.15);
}

[contenteditable="true"]:empty:before {
  content: attr(placeholder);
  display: block;
  color: #aaa;
}

.MuiGridPanel-root {
  top: -60px !important;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: 500 !important;
}

.expandedRowClass {
  background-color: #edf4f9;
}

.MuiDataGrid-row {
  transition: 0.1s;
}
